export const menuData = [
    {
        name: "Accueil", 
        link: "/"
    },
    {
        name: "Galerie", 
        link: "/galleryLight"
    },
    {
        name: "Bio", 
        link: "/bio"
    },
    {
        name: "Evènements", 
        link: "/event"
    },
    {
        name: "Me contacter", 
        link: "/email"
    }
]
